import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routeUrls } from './common/constants/routeUrls';
import { Language } from './pages/Language';
import { Welcome } from './pages/Welcome';
import { Streaming } from './pages/Streaming';

export const routes = (
  <Switch>
    <Route exact path={routeUrls.welcome} component={Welcome} />
    { <Route exact path={routeUrls.streaming} component={Streaming} /> }
    <Route component={Language} />
  </Switch>
);
