import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { languages } from '../../common/constants/languages';
import language from '../../common/language/language';
import c_next_logo from '../../media/cnext-logo-9sep24.png';
import cosentino_logo from '../../media/Asset9@2x.png';
import './Language.scss';

export class Language extends React.Component<any, any> {
  render() {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}>
        <div className='language'>
          <img className='logo__c-next' src={c_next_logo} alt="C-Next Logo" />
          <div className='text-container'>
            <div>{language.chall.selectLanguage.en}</div>
            <div>{language.chall.selectLanguage.es}</div>
          </div>
          <div className='button-container'>
            <button onClick={() => this.goToWelcome(languages.es)}>ESP</button>
           </div>
           <div className='button-container'> 
            <button onClick={() => this.goToWelcome(languages.en)}>ENG</button>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    );
  }

  goToWelcome = async (language: string) => {
    this.props.history.push(`/welcome/${language}`);
  }
}