import { IPersonaData, emptyIPersonaData } from './../../models/IPersonaData';
import * as msal from '@azure/msal-browser';
import { msalConfig } from "./msalConfig";

export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const logout = async () => {
    const logoutRequest = {
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI!,
        authority: process.env.REACT_APP_MSALAUTHORITY,
    }
    await msalInstance.handleRedirectPromise();
    await msalInstance.logoutRedirect(logoutRequest);
}


export const getUserData = async (): Promise<IPersonaData> => {
    debugger;
    const token = await getToken(['User.Read']);
    const account = msalInstance.getActiveAccount();

    let imgSrc = '';
    const params = {
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + token.accessToken,
            'Content-Type': 'image/jpeg'
        },
    };

    fetch('https://graph.microsoft.com/v1.0/me/photo/$value', params).then(function (response) {
        response.blob().then(function (miBlob) {
            var objectURL = URL.createObjectURL(miBlob);
            imgSrc = objectURL;
        });
    })

    return {
        ImageSrc: imgSrc,
        Name: account!.username,
        Email: account!.name!
    };
}

//probar con idToken o accessToken
const getToken = async (scopes?: Array<string>): Promise<msal.AuthenticationResult> => {
    const silentRequest = ParseScopes(scopes);

    return await getSilentRequest(silentRequest);
}

const getSilentRequest = async (silentRequest: msal.SilentRequest): Promise<msal.AuthenticationResult> => {
    try {
        const accounts = msalInstance.getAllAccounts();
        msalInstance.setActiveAccount(accounts[0]);

        var result = await msalInstance.acquireTokenSilent(silentRequest)

        return Promise.resolve(result);
    } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(silentRequest);
        }
        return Promise.reject(error);
    }
}

const ParseScopes = (scopes?: Array<string>): msal.SilentRequest => {
    let silentRequest = {} as msal.SilentRequest;

    if (scopes) {
        silentRequest = {
            scopes: scopes
        }
    }

    return silentRequest;
}